/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://6brvhlylazg3dmcepj5bcsbl4m.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-leiba2hpt5brzgtrnzghlet4ye",
    "aws_cloud_logic_custom": [
        {
            "name": "oneClickUnsubApi",
            "endpoint": "https://r5zemb7k62.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        },
        {
            "name": "subscriberConsentTrackingAPI",
            "endpoint": "https://s2d2il08d4.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "subscriber-consent-tracking-production",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
